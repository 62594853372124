<template>
    <div class="bb-onboarding">
      <div class="bg-cercles bb-onboarding__container show-back">
        <ResponsiveMobile :targetClass="['bb-onboarding-back']">
          <div class="back" v-if="!isNewUser">
            <div class="back__back">
              <a href="#" @click.prevent="previous()">
                <div class="back__name">
                  <img
                    src="../../assets/img/ico/ico-arrow-back.svg"
                    :alt="$t('generic.goback')"
                    class="back__arrow"
                  />
                </div>
              </a>
            </div>
          </div>
        </ResponsiveMobile>
  
        <div class="bb-onboarding__wrapper">
          <div class="bb-onboarding__header">
            <div class="bb-onboarding__step" v-if="totalSteps > 1">{{ $t("onboarding.configuring_your_list") }}: {{ currentStepNumber }}/{{ totalSteps }}</div>
            <div class="bb-onboarding__title">¿Nos das tu nombre?</div>
            <div class="bb-onboarding__description">
                Nos irá bien para personalizar tu lista
            </div>
          </div>
          <div class="bb-onboarding__content">
  
            <div class="bb-onboarding__form">
  
              <div class="bb-form-item">
                <div class="bb-form-label">Nombre</div>
                <input
                    v-model="name"
                    type="text"
                    class="bb-form"
                    name="name"
                    id="name"
                    placeholder="Tu nombre"
                    :disabled="nameCheckbox"
                  />
              </div>
  
              <div class="bb-form-item">
                <!--label class="bb-form-label">{{ $t("onboarding.noBaby") }}</label-->
  
                <div class="bb-radio">
                  <div class="bb-radio__items">
                    <div class="bb-radio__item">
                      <div class="bb-form-checkbox">
                        <input
                          type="checkbox"
                          name="noBaby"
                          id="noBaby"
                          value="no"
                          v-model="nameCheckbox"
                        />
                        <label for="noBaby" class="bb-form-checkbox__label">
                          Lo haré más adelante
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
  
              </div>
  
            </div>
  
            <div class="bb-onboarding__actions bb-onboarding__actions--no-margin">
              <loading-button v-if="loading"></loading-button>
              <button v-else class="button button--primary button--block" @click="next" :disabled="disabled">
                {{ $t("generic.next") }}
              </button>
            </div>
  
          </div>
        </div>
  
      </div>
    </div>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  
  // eslint-disable-next-line no-unused-vars
  //import moment_es from "moment/locale/es";
  
  import LoadingButton from "../../components/LoadingButton";
  import ResponsiveMobile from "../../components/responsive/ResponsiveMobile";
  import Steps from "./mixins/Steps";
  
  export default {
    name: "OnboardingStepName",
    components: {
      LoadingButton,
      ResponsiveMobile,
    },
    mixins: [
      Steps,
    ],
    props: {
      email: {
        type: String,
        required: false
      }
    },
    data() {
      return {
        loading: false,
        name: "",
        nameCheckbox: false,
      };
    },
    computed: {
      ...mapGetters({
        user: "user"
      }),
      disabled() {
        const isNameInvalid = this.name.trim() === "";
        return isNameInvalid && !this.nameCheckbox;
      },
    },
    methods: {
      async next() {
        this.loading = true;
        const stepData = {
          name: this.name,
        };
        try {
          if (this.name && !this.nameCheckbox) {
            await this.$store.dispatch("updateName", this.name);
          }
          await this.goToNextStep({
            [this.$options.name]: stepData,
          });
        } catch (error) {
          console.error(error);
        } finally {
          this.loading = false;
        }
      },
      async previous() {
        await this.goToPreviousStep(this.$options.name);
      },
    },
    mounted() {
      
    }
  }
  </script>
  